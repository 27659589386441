@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.project-block {
  @apply flex w-full flex-col items-center justify-center md:w-auto lg:w-[990px];
}

.project-grid {
  @apply grid w-full grid-flow-dense grid-cols-1 gap-x-5 gap-y-5 px-10 pb-20 children:rounded-3xl md:w-screen lg:w-[990px] lg:grid-cols-3;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -3176px;
  }
}

.animate-sneakpeek {
  min-width: 6352px;
  animation: marquee 90s linear infinite;
  top: 50%;
  transform: translate(0, -50%);
}

.animate-sneakpeek:hover {
  animation-play-state: paused;
}
